<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h3>Usuários sem professores</h3>
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item active">
              Mostrando usuários com os mesmos interesses que você e sem
              professores.
            </li>
          </ol>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div v-for="user in users.items" :key="user.id" class="col-xl-4 col-md-6">
        <div class="card directory-card">
          <div class="card-body">
            <div class="media">
              <img
                :src="
                  user.avatar
                    ? $getImageUrl(user.avatar.originalName)
                    : $defaultUserAvatar
                "
                :alt="user.username"
                class="img-fluid img-thumbnail rounded-circle avatar-lg"
              />
              <div class="media-body ml-3">
                <router-link
                  :to="{
                    name: 'profile',
                    params: {
                      username: user.username,
                    },
                  }"
                  target="_blank"
                >
                  <h5 class="text-primary font-size-18 mt-0 mb-1">
                    {{ user.name }} {{ user.surname }}
                  </h5>
                  <p class="font-size-12 mb-2">@{{ user.username }}</p>
                </router-link>
                <template v-if="user.email">
                  <p class="mb-0">{{ user.email }}</p>
                </template>
              </div>
            </div>
            <hr />
            <template v-if="user.lastOnline != null">
              <p class="mb-0">
                <strong>Última vez online:</strong>
                {{ user.lastOnline | moment("from") }}
              </p>
            </template>

            <b-button
              @click.prevent="contactUser(user)"
              pill
              variant="info"
              class="mx-auto d-block"
            >
              Contatar
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../layouts/main";
import appConfig from "@/app.config";
import * as api from "@/api";
import moment from "moment";

export default {
  moment,

  page: {
    title: "Usuários para serem mentorados",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    Layout,
  },

  data() {
    return {
      users: [],
    };
  },

  created() {
    this.getUsers();
  },

  methods: {
    getUsers() {
      api
        .findUserWithoutMentors({
          questionId: this.$route.query.questionId,
        })
        .then((users) => {
          this.users = users;
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao carregar professores!", "error");
        });
    },

    contactUser(user) {
      this.$router.push({
        name: "createConversation",
        query: {
          receiverId: user.id,
        },
      });
    },
  },
};
</script>
