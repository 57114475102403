var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"page-title-box"},[_c('h3',[_vm._v("Usuários sem professores")]),_c('ol',{staticClass:"breadcrumb mb-0"},[_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Mostrando usuários com os mesmos interesses que você e sem professores. ")])])])])]),_c('div',{staticClass:"row mb-3"},_vm._l((_vm.users.items),function(user){return _c('div',{key:user.id,staticClass:"col-xl-4 col-md-6"},[_c('div',{staticClass:"card directory-card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"media"},[_c('img',{staticClass:"img-fluid img-thumbnail rounded-circle avatar-lg",attrs:{"src":user.avatar
                  ? _vm.$getImageUrl(user.avatar.originalName)
                  : _vm.$defaultUserAvatar,"alt":user.username}}),_c('div',{staticClass:"media-body ml-3"},[_c('router-link',{attrs:{"to":{
                  name: 'profile',
                  params: {
                    username: user.username,
                  },
                },"target":"_blank"}},[_c('h5',{staticClass:"text-primary font-size-18 mt-0 mb-1"},[_vm._v(" "+_vm._s(user.name)+" "+_vm._s(user.surname)+" ")]),_c('p',{staticClass:"font-size-12 mb-2"},[_vm._v("@"+_vm._s(user.username))])]),(user.email)?[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(user.email))])]:_vm._e()],2)]),_c('hr'),(user.lastOnline != null)?[_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v("Última vez online:")]),_vm._v(" "+_vm._s(_vm._f("moment")(user.lastOnline,"from"))+" ")])]:_vm._e(),_c('b-button',{staticClass:"mx-auto d-block",attrs:{"pill":"","variant":"info"},on:{"click":function($event){$event.preventDefault();return _vm.contactUser(user)}}},[_vm._v(" Contatar ")])],2)])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }